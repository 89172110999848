import React, {Component} from 'react';
import {Container} from 'react-bootstrap';
import moment from 'moment';
import webClient from '../api/webClient';
import {FormattedMessage} from 'react-intl';
import InfoRow from '../components/apukomponentit/yritysrivi';
import {ReactComponent as SearchIcon} from '../images/help.svg';
import '../styles/company.css';
import {toimintaEnum} from '../static/toimintaEnum';
import * as R from 'ramda';
import CompanyIndustries from './companyView/CompanyIndustries';
import CompanySanctions from '../components/CompanySanctions';

class Company extends Component {
  state = {
    yritys: null,
    loading: false
  };

  componentDidMount() {
    this.fetchCompanyInfo();
  }

  componentDidUpdate(prevProps) {
    if (this.props.companyId !== prevProps.companyId) {
      this.fetchCompanyInfo();
    }
  }

  fetchCompanyInfo = () => {
    this.setState({loading: true});
    webClient
      .post('Yrityksenperustiedot/Hae', {yritysid: this.props.companyId})
      .then(response => {
        this.setState({
          yritys: response.data,
          loading: false
        });
      })
      .catch(error => {
        this.setState({loading: false});
      });
  };

  renderBusinessType = businessType => {
    const type = R.find(R.propEq('value', businessType))(toimintaEnum);
    return type[`label_${this.props.language}`];
  };

  handlePositions = (asemat, locale) => {
    const localizedPositions = asemat.map(asema => (locale === 'fi' ? asema.AsemaFi : asema.AsemaSv));
    return localizedPositions.join(', ');
  };

  renderResponsiblePersons = persons => {
    return persons.map((person, index) => {
      const positions = this.handlePositions(person.Asemat, this.props.language);
      if (index === 0) {
        return (
          <InfoRow
            key={index}
            labelId="company_responsible_persons"
            arvo={`${person.Nimi}, ${positions}`}
          />
        );
      }
      return <InfoRow key={1} arvo={`${person.Nimi}, ${positions}`} />;
    });
  };

  render() {
    if (this.state.loading) {
      return (
        <h4>
          <FormattedMessage id="general_loading" defaultMessage="Ladataan..." />
        </h4>
      );
    }
    if (this.state.yritys === null) {
      return (
        <p>
          <FormattedMessage id="general-company_not_found" defaultMessage="Yritystä ei löytynyt" />
        </p>
      );
    }
    const result = this.state.yritys;
    return (
      <section className="company-info-container-wrapper company-info-container">        
          <Container className="company-info-container_rows">
            <button autoFocus className="company-info__close-button" onClick={this.props.closeCompanyInfo}>
              <FormattedMessage id="company-close_info" defaultMessage="Sulje lisätiedot" />
          </button>
            <h1>{result.Toiminimi}</h1>
            <InfoRow labelId="company-company_aux_name" arvo={result.Aputoiminimi} />
            <InfoRow labelId="company-businessID" arvo={result.YTunnus} />
            <InfoRow
              labelId="company-registry_date"
              arvo={moment(result.RekisterointiPvm).format('DD.MM.YYYY')}
            />
            {result.Toimipaikat.map((toimipaikka, index) => {
              const locationValue = `${toimipaikka.Katuosoite} 
              ${toimipaikka.Postinumero} ${toimipaikka.Postitoimipaikka}`;
              if (index === 0) {
                return <InfoRow key={index} labelId="company-offices" arvo={locationValue} />;
              }
              return <InfoRow key={index} arvo={locationValue} />;
            })}
            <CompanyIndustries industries={result.Toimialat} />
            {result.Vastuuhenkilot.length > 0 &&
              this.renderResponsiblePersons(result.Vastuuhenkilot)}
            <CompanySanctions
              sanktiot={this.state.yritys.Sanktiot}
              loading={this.state.loading}
            />
          </Container>
          <div className="email-address">
            <span>
              <SearchIcon />
              <FormattedMessage
                id="company-contact"
                defaultMessage="Onko tiedoissa virhe? Ota yhteyttä: "
              />
              <a href="mailto:rahanpesu@avi.fi">rahanpesu@avi.fi</a>
              <br />
              <FormattedMessage
                id="company-use_asiointipalvelu"
                defaultMessage="Muuttuneet tiedot voit ilmoittaa asiointipalvelussa: "
              />
              <a
                href="https://sahkoinenasiointi.ahtp.fi/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FormattedMessage
                  id="general_asiointipalvelu"
                  defaultMessage="Aluehallinnon asiointipalvelu"
                />
              </a>
            </span>
          </div>
      </section>
    );
  }
}

export default Company;
