import React from 'react';
import {FormattedMessage} from 'react-intl';
import {ToggleButtonGroup, ToggleButton} from 'react-bootstrap';

const BusinessFilterToolbar = props => {
  return (
    <fieldset>
      <legend style={{display: 'none'}}>
        {<FormattedMessage id="search_bar-search_by_type" />}
      </legend>
      <ToggleButtonGroup
        type="checkbox"
        onChange={props.onChange}
        className={props.className}
        name="businessFilter"
        defaultValue={['MainArea']}
      >
        <ToggleButton
          type="checkbox"
          variant="outline-light"
          value={'MainArea'}
          name="mainbusiness"
          disabled={props.disableButtons === true}
        >
          <FormattedMessage id="search_bar-mainbusiness" defaultMessage="Päätoiminen" />
        </ToggleButton>
        <ToggleButton
          type="checkbox"
          variant="outline-light"
          value={'SideArea'}
          name="sidebusiness"
          disabled={props.disableButtons === true}
        >
          <FormattedMessage id="search_bar-sidebusiness" defaultMessage="Sivutoiminen" />
        </ToggleButton>
      </ToggleButtonGroup>
    </fieldset>
  );
};

export default BusinessFilterToolbar;
