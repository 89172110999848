import React, {Component} from 'react';
import {BrowserRouter, Route} from 'react-router-dom';
import {Layout} from './hoc/layout';
import Home from './Views/home';
import SearchResults from './Views/SearchResults';
import Yritysnakyma from './Views/company';
import Header from './components/header';
import Footer from './components/footer';
import {IntlProvider} from 'react-intl';
import messages_fi from './static/locales/fi.json';
import messages_sv from './static/locales/sv.json';
import RaksiAlert from './components/Alert';

import 'react-widgets/dist/css/react-widgets.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import './styles/common.css';
import webClient from './api/webClient';

const messages = {
  fi: messages_fi,
  sv: messages_sv
};

export default class App extends Component {
  state = {
    language: 'fi',
    languageMessages: messages.fi,
    notification: null
  };

  componentDidMount() {
    webClient.get('/notifications').then(result => {
      this.setState({notification: result.data});
    });
    window.document.documentElement.lang = 'fi-FI';
  }

  hideAlert = () => {
    this.setState(prevState => ({
      notification: null
    }));
  };

  onLanguageChange = newLang => {
    if (newLang === 'fi-FI') {
      this.setState({languageMessages: messages.fi, language: 'fi'});
      window.document.documentElement.lang = newLang;
    } else if (newLang === 'sv-FI') {
      this.setState({languageMessages: messages.sv, language: 'sv'});
      window.document.documentElement.lang = newLang;
    }
  };

  onSearch = (nameorid, businessType, businessFilter, registryType) =>
    this.setState({nameorid, businessType, businessFilter, registryType});

  render() {
    return (
      <IntlProvider locale={this.state.language} messages={this.state.languageMessages}>
        <BrowserRouter>
          {this.state.notification && (
            <RaksiAlert
              language={this.state.language}
              notification={this.state.notification}
              onClose={this.hideAlert}
            />
          )}
          <div className="app_wrapper">
            <Header
              title={document.title}
              headingLevel="h1"
              language={this.state.language}
              onLanguageChange={this.onLanguageChange}
            />
            <Layout language={this.state.language} onSearch={this.onSearch}>
              <Route path="/" exact render={props => <Home {...props} />} />
              <Route
                path="/yritykset"
                render={props => (
                  <SearchResults
                    {...props}
                    nameorid={this.state.nameorid}
                    businessFilter={this.state.businessFilter}
                    businessType={this.state.businessType}
                    registryType={this.state.registryType}
                    language={this.state.language}
                  />
                )}
              />
            </Layout>
            <Route path="/yritykset/:id" render={props => <Yritysnakyma {...props} />} />
          </div>
          <Footer />
        </BrowserRouter>
      </IntlProvider>
    );
  }
}
