import React from 'react';
import {ReactComponent as SearchIcon} from '../images/search.svg';
import {useIntl} from 'react-intl';

const SearchInput = props => {
  const intl = useIntl();
  return (
    <div className="search-container-input-container">
      <SearchIcon />
      <input
        name="nameorid"
        type="search"
        id="searchbox"
        onChange={props.onChange}
        placeholder={intl.formatMessage({
          id: 'search_bar-search_for_company',
          defaultMessage: 'Hae elinkeinonharjoittajaa (vähintään 3 merkkiä)'
        })}
      />
    </div>
  );
};
export default SearchInput;
