import React, {Component} from 'react';

class RaksiAlert extends Component {
  render() {
    return (
      <>
        <div
          style={{marginBottom: '0'}}
          className="fade alert alert-warning alert-dismissible show"
        >
          {this.props.language === 'fi' && <b lang="fi-FI">{this.props.notification.TextFI}</b>}
          {this.props.language === 'sv' && <b lang="sv-FI">{this.props.notification.TextSV}</b>}
        </div>
        <button className="raksi-alert-button close " onClick={() => this.props.onClose()}>
          <span aria-hidden="true">×</span>
          <span className="sr-only">
            {this.props.language === 'fi' && 'Sulje ilmoitus'}
            {this.props.language === 'sv' && 'Nära anmälan'}
          </span>
        </button>
      </>
    );
  }
}

export default RaksiAlert;
