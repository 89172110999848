export const toimintaEnum = [
  {
    value: "kirjanpitajat",
    label_fi: "Kirjanpitäjät",
    label_sv: "Bokförare",
  },
  {
    value: "oikeudelliset",
    label_fi: "Oikeudelliset palvelut",
    label_sv: "Juridiska tjänster",
  },
  {
    value: "rahoituspalvelut",
    label_fi: "Rahoituspalvelut",
    label_sv: "Finansieringstjänster",
  },
  {
    value: "sijoituspalvelut",
    label_fi: "Sijoituspalveluiden oheispalvelut",
    label_sv: "Kompletterande tjänster till investeringstjänster",
  },
  {
    value: "taidekauppiaat",
    label_fi: "Taidekauppiaat",
    label_sv: "Konsthandlare",
  },

  {
    value: "tavarakauppiaat",
    label_fi: "Tavarakauppiaat",
    label_sv: "Produktförsäljare",
  },
  {
    value: "valuutanvaihtajat",
    label_fi: "Valuutanvaihtajat",
    label_sv: "Valutaväxlare",
  },
  {
    value: "veroneuvontapalvelut",
    label_fi: "Veroneuvontapalvelut",
    label_sv: "Skatterådgivningstjänster",
  },
  {
    value: "yrityspalvelut",
    label_fi: "Yrityspalvelut",
    label_sv: "Företagstjänster",
  },
];
