import React from 'react';
import {Combobox} from 'react-widgets';
import {useIntl} from 'react-intl';
import {toimintaEnum} from '../static/toimintaEnum';

const getOptions = locale => {
  return toimintaEnum.map(toiminta => {
    return {value: toiminta.value, label: toiminta[`label_${locale}`]};
  });
};

const ComboboxSearch = props => {
  const intl = useIntl();
  return (
    <div className="search-container-input-container">
      <Combobox
        name="businessType"
        data={getOptions(intl.locale)}
        textField="label"
        inputProps={{type: 'search'}}
        value={props.value}
        placeholder={intl.formatMessage({
          id: 'combobox_placeholder',
          defaultMessage: 'Toiminta'
        })}
        caseSensitive={false}
        minLength={1}
        filter="contains"
        onChange={props.onChange}
      />
    </div>
  );
};
export default ComboboxSearch;
