import React from 'react';
import {Row, Col} from 'react-bootstrap';
import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';

const Yritysrivi = props => (
  <Row className="company-info-row">
    <Col xs={props.col1 ?? 6}>
      {props.labelId ? <FormattedMessage id={props.labelId} /> : null}
    </Col>
    <Col xs={props.col2 ?? 6} className="company-info-row__value">
      {props.arvo}
    </Col>
  </Row>
);

Yritysrivi.propTypes = {
  labelId: PropTypes.string,
  arvo: PropTypes.string
};

export default Yritysrivi;
