import React, {Component} from 'react';
import webClient from '../api/webClient';
import Results from './SearchResultsByCompanyType';
import Company from './company';
import '../styles/searchResults.css';

class SearchResults extends Component {
  state = {
    loading: false,
    companies: [],
    selectedCompany: null,
    buttonRef: null
  };

  componentDidMount() {
    const nameParam = this.parseQueryParam(1);
    const businessFilterParam = this.parseQueryParam(2);
    const businessTypeParam = this.parseQueryParam(3);
    const registryTypeParam = this.parseQueryParam(4);
    if (
      (this.state.companies.length === 0 && typeof nameParam !== 'undefined') ||
      (null &&
        typeof businessFilterParam !== 'undefined' &&
        typeof businessTypeParam !== 'undefined' &&
        typeof registryTypeParam !== 'undefined')
    ) {
      this.getCompanies();
    }
    document.addEventListener('keydown', this.escFunction, false);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.nameorid !== this.props.nameorid ||
      prevProps.businessFilter !== this.props.businessFilter ||
      prevProps.businessType !== this.props.businessType ||
      prevProps.registryType !== this.props.registryType
    ) {
      this.getCompanies();
    }
  }
  componentWillUnmount() {
    document.removeEventListener('keydown', this.escFunction, false);
  }
  escFunction = event => {
    if (event.keyCode === 27) {
      this.setState({selectedCompany: null});
    }
  };

  parseQueryParam = id => {
    const a = this.props.location.search.split('?')[id];
    return a.split('=')[1];
  };

  selectItem = (id, ref) => {
    this.setState({selectedCompany: id, buttonRef: ref});
  };

  getCompanies = () => {
    const name = this.props.nameorid ? this.props.nameorid : this.parseQueryParam(1);
    const businessAreaFilter = this.props.businessFilter
      ? this.props.businessFilter
      : this.parseQueryParam(2);
    const businessAreaCode = this.props.businessType
      ? this.props.businessType.value
      : this.parseQueryParam(3);
    const registryType = this.props.registryType
      ? this.props.registryType
      : this.parseQueryParam(4);
    this.setState({loading: true});
    webClient
      .post('/Yrityksenperustiedot/Etsi', {
        BusinessAreaFilter: businessAreaFilter,
        BusinessAreaCode: businessAreaCode,
        Name: name,
        RegistryType: registryType ?? 'registered'
      })
      .then(response => {
        const values = response.data;
        this.setState({
          companies: values,
          loading: false
        });
      })
      .catch(error => {
        this.setState({
          loading: false
        });
      });
  };

  render() {
    let resultClassNames = 'company-table-container';
    if (this.state.selectedCompany) {
      resultClassNames += ' company-table-container-hidden';
    }
    return (
      <div className="body-container">
        <div className={resultClassNames}>
          <Results
            searchResultsArray={this.state.companies}
            selectItem={this.selectItem}
            loading={this.state.loading}
            selectedCompany={this.state.selectedCompany}
            language={this.props.language}
            searchType={this.props.registryType ?? 'registered'}
          ></Results>
        </div>
        {this.state.selectedCompany && (
          <Company
            language={this.props.language}
            companyId={this.state.selectedCompany}
            closeCompanyInfo={() => {
              this.setState({selectedCompany: null});
              this.state.buttonRef.current.focus();
            }
            }
          />
        )}
      </div>
    );
  }
}

export default SearchResults;
