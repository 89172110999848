import React from 'react';
import * as R from 'ramda';
import {toimintaEnum} from '../../static/toimintaEnum';
import InfoRow from '../../components/apukomponentit/yritysrivi';
import {useIntl} from 'react-intl';

const renderBusinessType = (businessType, locale) => {
  const type = R.find(R.propEq('value', businessType))(toimintaEnum);
  return type[`label_${locale}`];
};

const CompanyIndustries = ({industries}) => {
  const intl = useIntl();
  return industries.map((industry, index) => {
    if (index === 0) {
      return (
        <InfoRow
          key={index}
          labelId="company-business_type2"
          arvo={renderBusinessType(industry, intl.locale)}
        />
      );
    }
    return <InfoRow key={index} arvo={renderBusinessType(industry, intl.locale)} />;
  });
};

export default CompanyIndustries;
