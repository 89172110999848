import React, { Component } from "react";
import SearchBar from "../components/searchBar";

export class Layout extends Component {
  render() {
    return (
      <div className="main">
        <SearchBar
          language={this.props.language}
          onSearch={this.props.onSearch}
        />
        {this.props.children}
      </div>
    );
  }
}
