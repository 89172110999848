import React from 'react';
import moment from 'moment';
import InfoRow from './apukomponentit/yritysrivi';
import '../styles/company.css';
import {useIntl} from 'react-intl';

const CompanySanctions = props => {
  const intl = useIntl();

  const renderForceOfLaw = value => {
    if(value === true) {
        return intl.formatMessage({
          id: 'sanctions-force_of_law_true'
        }).toLowerCase();
      }
      else {
        return intl.formatMessage({
          id: 'sanctions-force_of_law_false'
        }).toLowerCase();
      }
  };

  return (
    <div>
      {props?.sanktiot?.All?.map((item, index) => {
        const sanctionDetails = `${
          intl.locale === 'fi' ? item.TypeLabelFi : item.TypeLabelSv
        }, 
        ${intl.formatMessage({
          id: 'sanctions-docket'
        })}
        ${item.DiaariNumber}${intl.formatMessage({
          id: 'sanctions-verdict_date'
        })} ${moment(item.DecisionDate).format('DD.MM.YYYY')}. 
        ${intl.formatMessage({
          id: 'sanctions-force_of_law'
        })} ${item.Legal !== null ? renderForceOfLaw(item.Legal) : item.Legality}. 
        ${
          intl.locale === 'fi' ? item.DescriptionFi : item.DescriptionSv
        }`;
        if (index === 0) {
          return (
            <InfoRow
              key={index}
              labelId="sanctions-title"
              arvo={sanctionDetails}
              col1={12}
              col2={12}
            />
          );
        }
        return (
          <InfoRow key={index} arvo={sanctionDetails} col1={12} col2={12} />
        );
      })}
    </div>
  );
};

export default CompanySanctions;
