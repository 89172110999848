import React from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';
import '../styles/info-block.css';

const infoBlock = props => (
  <div className="info-block">
    {props.children}
    <h2>
      <FormattedMessage id={props.title} />
    </h2>
    <p>
      <FormattedMessage id={props.desc} />
    </p>
  </div>
);

infoBlock.propTypes = {
  image: PropTypes.element,
  title: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired
};

export default infoBlock;
