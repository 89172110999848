import React from 'react';
import PropTypes from 'prop-types';
import '../styles/companyTable.css';
import {FormattedMessage} from 'react-intl';
import ResultCounter from '../components/ResultCounter';

const companyTable = props => {
  return (
    <div>
      <h2>
        <FormattedMessage
          id="company-table-header"
          defaultMessage="Rahanpesun valvontarekisterihaun tulokset"
        />
      </h2>
      <ResultCounter counter={props.searchResultsArray.length} />
      <table className="company-table">
        <thead>
          <tr>
            {props.columns.map((column, index) => {
              if (column.className)
                return (
                  <th key={index} className={column.className}>
                    {column.name}
                  </th>
                );
              return <th key={index}>{column.name}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {!props.loading &&
            props.searchResultsArray.map(item =>
              props.renderSearchResults(item, props.selectedCompany, props.selectItem, props.locale)
            )}
        </tbody>
      </table>
      {props.loading && (
        <p style={{textAlign: 'center'}}>
          <FormattedMessage id="general_loading" defaultMessage="Ladataan..." />{' '}
        </p>
      )}
      {!props.loading && props.searchResultsArray.length === 0 && (
        <p style={{textAlign: 'center'}}>
          <FormattedMessage id="general_no_results" defaultMessage="Ei tuloksia" />
        </p>
      )}
    </div>
  );
};

companyTable.propTypes = {
  searchResultsArray: PropTypes.array.isRequired
};

export default companyTable;
