import React from 'react';
import {FormattedMessage} from 'react-intl';
import {ButtonToolbar, ToggleButton, ToggleButtonGroup} from 'react-bootstrap';

const RegistryTypeToolbar = props => {
  return (
    <ButtonToolbar>
      <fieldset>
          <legend style={{display: 'none'}}><FormattedMessage id="company_counter-company_count"/></legend>
        <ToggleButtonGroup
          type="radio"
          name={'type'}
          onChange={props.onToggleChange}
          defaultValue="registered"
        >
          <ToggleButton value="registered" type="radio" name="type" variant="outline-light">
            <FormattedMessage id="search_bar-registrations" defaultMessage="Rekisteröidyt" />
          </ToggleButton>
          <ToggleButton value="deleted" type="radio" name="type" variant="outline-light">
            <FormattedMessage id="search_bar-deleted" defaultMessage="Poistetut" />
          </ToggleButton>
        </ToggleButtonGroup>
      </fieldset>
    </ButtonToolbar>
  );
};

export default RegistryTypeToolbar;
