import React, { useRef } from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import moment from 'moment';
import CompanyTable from '../components/CompanyTable';
import {toimintaEnum} from '../static/toimintaEnum';
import * as R from 'ramda';

const registeredCols = [
  {
    name: <FormattedMessage id="company-company_name" />
  },
  {
    name: <FormattedMessage id="company-business_type" />
  },
  {
    name: <FormattedMessage id="company-businessID" />,
    className: 'no-priority'
  },
  {
    name: <FormattedMessage id="company-address" />,
    className: 'no-priority'
  },
  {
    name: <FormattedMessage id="company-sanctions" />,
    className: 'no-priority'
  },
  {
    className: 'no-priority'
  }
];

const deletedCols = [
  {
    name: <FormattedMessage id="company-company_name" />
  },
  {
    name: <FormattedMessage id="company-business_type" />
  },
  {
    name: <FormattedMessage id="company-businessID" />,
    className: 'no-priority'
  },
  {
    name: <FormattedMessage id="company-deleted" />,
    className: 'no-priority'
  },
  {
    name: <FormattedMessage id="company-deletion_reason" />,
    className: 'no-priority'
  },
  {
    className: 'no-priority'
  }
];

const renderBusinessType = (businessType, locale) => {
  const type = R.find(R.propEq('value', businessType))(toimintaEnum);
  if (locale === 'fi') {
    return type.label_fi;
  }
  return type.label_sv;
};

const RegisteredCompaniesRender = (item, selectedCompany, selectItem, locale) => {
  const itemClassName =
    selectedCompany === item.Id ? 'company-table__item selected' : 'company-table__item';
  
    const buttonRef = useRef(null);
  
  return (
    <tr key={item.Id} className={itemClassName} onClick={() => selectItem(item.Id, buttonRef)}>
      <td>{item.Toiminimi}</td>
      <td>{renderBusinessType(item.Toiminta, locale)}</td>
      <td className="no-priority td_size">{item.YTunnus}</td>
      <td className="no-priority">{item.Osoite}</td>
      <td className="no-priority">
        {locale === 'fi' ? item.Sanktio?.TypeLabelFi : item.Sanktio?.TypeLabelSv}
      </td>
      <td>
        <button className="company-table__item_button" ref={buttonRef} onClick={() => selectItem(item.Id, buttonRef)}>
          <FormattedMessage id="company-open_info" />
        </button>
      </td>
    </tr>
  );
};

const DeletedCompaniesRender = (item, selectedCompany, selectItem, locale) => {
  const itemClassName =
    selectedCompany === item.Id ? 'company-table__item selected' : 'company-table__item';
  
  const buttonRef = useRef(null);
  
  return (
    <tr key={item.Id} className={itemClassName} onClick={() => selectItem(item.Id, buttonRef)}>
      <td>{item.Toiminimi}</td>
      <td>{renderBusinessType(item.Toiminta, locale)}</td>
      <td className="no-priority">{item.YTunnus}</td>
      <td className="no-priority">{moment(item.Poistotiedot?.Pvm).format('DD.MM.YYYY')}</td>
      <td className="no-priority">
        {locale === 'fi' ? item.Poistotiedot?.KuvausFi : item.Poistotiedot?.KuvausSv}
      </td>
      <td>
        <button className="company-table__item_button" ref={buttonRef} onClick={() => selectItem(item.Id, buttonRef)}>
          <FormattedMessage id="company-open_info" />
        </button>
      </td>
    </tr>
  );
};

const SearchResults = props => {
  let columns, renderSearchResults;
  const intl = useIntl();

  switch (props.searchType) {
    case 'registered':
      columns = registeredCols;
      renderSearchResults = RegisteredCompaniesRender;
      break;
    case 'deleted':
      columns = deletedCols;
      renderSearchResults = DeletedCompaniesRender;
      break;
    default:
      return null;
  }

  return (
    <CompanyTable
      columns={columns}
      renderSearchResults={renderSearchResults}
      locale={intl.locale}
      {...props}
    />
  );
};

export default SearchResults;
