import React from 'react';
import '../styles/common.css';

import {FormattedMessage, useIntl} from 'react-intl';

const Footer = () => {
  const d = new Date();
  const currentYear = d.getFullYear();

  const intl = useIntl();

  return (
    <footer className="footer">
      <p>
        <FormattedMessage id="footer-read_more_info" defaultMessage="Lue lisää " />
        <a
          style={{textDecoration: 'underline'}}
          href={
            intl.locale === 'fi'
              ? 'https://avi.fi/asioi/yritys-tai-yhteiso/valvonta-ja-kantelut/raha-ja-omaisuus/rahanpesulain-valvonta'
              : 'https://avi.fi/sv/valj-arende/foretag-eller-organisation/tillsyn-och-klagomal/pengar-och-formogenhet/lagen-om-penningtvatt'
          }
        >
          <FormattedMessage id="footer-read_more_info_avi" defaultMessage="aluehallintoviraston " />
        </a>
        <FormattedMessage id="footer-read_more_info_pages" defaultMessage="sivuilta " />|{' '}
        <a style={{textDecoration: 'underline'}} href="http://www.avi.fi/web/avi/tietosuoja">
          <FormattedMessage id="footer-data_protection" defaultMessage="Tietosuoja" />
        </a>
        | <FormattedMessage id="footer-fill_application_info" defaultMessage="Täytä hakemus" />{' '}
        <a style={{textDecoration: 'underline'}} href="https://sahkoinenasiointi.ahtp.fi">
          <FormattedMessage id="footer-fill_application_info2" defaultMessage="täältä" />
        </a>
      </p>
      <p>
        © {currentYear} <FormattedMessage id="general_avi" defaultMessage="Aluehallintovirasto" />
      </p>
    </footer>
  );
};

export default Footer;
