import React from 'react';

const LanguageSwitch = props => {
  const languageOnChange = e => {
    props.onLanguageChange(e.target.lang);
  };

  return (
    <div className="header__language_switch">
      <fieldset>
        <legend style={{display: 'none'}}>
          {props.language === 'fi' ? 'Suomeksi' : 'På svenska'}
        </legend>
        <div role="group" className="btn-group btn-group-toggle" onClick={e => languageOnChange(e)}>
          <button
            className={
              props.language === 'fi' ? 'btn active btn-outline-light' : 'btn btn-outline-light'
            }
            lang="fi-FI"
          >
            Suomeksi
          </button>
          <button
            className={
              props.language === 'sv' ? 'btn active btn-outline-light' : 'btn btn-outline-light'
            }
            lang="sv-FI"
          >
            På svenska
          </button>
        </div>
      </fieldset>
    </div>
  );
};

export default LanguageSwitch;
