import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router';
import {FormattedMessage} from 'react-intl';
import SearchInput from './SearchInput';
import ComboboxSearch from './Combobox';
import BusinessFilterToolbar from './BusinessFilterToolbar';
import '../styles/searchBar.css';
import CompanyCounter from './CompanyCounter';
import RegistryTypeToolbar from './RegistryTypeToolbar';

class Search extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nameorid: '',
      businessType: null,
      disableButtons: true,
      businessFilter: 'MainArea',
      registryType: 'registered'
    };
  }

  onChangeSearchInput = e =>
    this.setState({
      nameorid: e.target.value
    });

  onChangeComboBox = e => {
    let searchType = 'businessType';
    let value = e;
    this.setState({businessType: e});
    const object = {
      searchType,
      value
    };
    this.showButtons(object);
  };

  onChangeBusinessFilter = e => {
    this.setState({businessFilter: e});
    let value = e[0];
    //We check whether the business is mainbusiness or sidebusiness and set it to state
    switch (e.length) {
      case 0:
        this.setState({businessFilter: 'both'});
        value = 'both';
        break;
      case 1:
        this.setState({businessFilter: value});
        value = e[0];
        break;
      case 2:
        this.setState({businessFilter: 'both'});
        value = 'both';
        break;
      default:
        break;
    }
  };

  updateUrl = () => {
    const {nameorid, businessFilter, businessType, registryType} = this.state;
    this.props.history.push({
      pathname: '/yritykset',
      search: `?nameorid=${nameorid}?businessFilter=${businessFilter}?businesstype=${
        businessType ? businessType.value : ''
      }?registryType=${registryType ? registryType : 'registered'}`
    });
  };

  onSubmit = e => {
    e.preventDefault();
    const {nameorid, businessType, businessFilter, registryType} = this.state;
    this.updateUrl(nameorid, businessFilter, businessType, registryType);
    this.props.onSearch(nameorid, businessType, businessFilter, registryType);
  };

  onRegistryTypeChanged = value =>
    this.setState({
      registryType: value
    });

  showButtons(object) {
    if (object.value) {
      this.setState({disableButtons: false});
    } else {
      this.setState({disableButtons: true});
      this.setState({businessFilter: 'both'});
    }
  }

  render() {
    return (
      <form onSubmit={this.onSubmit}>
        <div className="search-container">
          <div>
            <label style={{display: 'none'}} htmlFor="searchbox">
              <FormattedMessage id="search_bar-search_for_company" />
            </label>
            <SearchInput onChange={this.onChangeSearchInput} />

            <ComboboxSearch
              language={this.props.language}
              onChange={this.onChangeComboBox}
              value={this.state.businessType}
            />
            <div className="search_bar-bottom_flexcontainer_row">
              <BusinessFilterToolbar
                className="search_bar-bottom_flexcontainer_row-first_element"
                disableButtons={this.state.disableButtons}
                onChange={this.onChangeBusinessFilter}
                businessFilter={this.state.businessFilter}
              />
              <RegistryTypeToolbar onToggleChange={this.onRegistryTypeChanged} />
            </div>

            <div className="search_bar-bottom_flexcontainer_row">
              <button onClick={this.onSubmit}>{<FormattedMessage id="search_bar-search" />}</button>
            </div>
            <div className="search_bar-bottom_flexcontainer_row">
              <div className="search_bar-company_counter">
                <CompanyCounter />
              </div>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

Search.propTypes = {
  onSearch: PropTypes.func.isRequired,
  history: PropTypes.any
};

export default withRouter(Search);
