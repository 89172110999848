import React from 'react';
import {Link} from 'react-router-dom';
import logoFi from '../images/logo_fi.svg';
import logoSv from '../images/logo_sv.svg';
import '../styles/header.css';
import {FormattedMessage} from 'react-intl';
import LanguageSwitch from './LanguageSwitch';

const renderLogo = language => {
  if (language === 'fi') {
    return <img alt="Aluehallintovirasto logo" className="logo__fi" src={logoFi} />;
  } else {
    return <img alt="Aluehallintovirasto logo" className="logo__sv" src={logoSv} />;
  }
};

const header = props => {
  const {headingLevel, title} = props;
  const Title = headingLevel;

  return (
    <header className="header">
      <Title style={{display: 'none'}}>{title}</Title>
      <LanguageSwitch language={props.language} onLanguageChange={props.onLanguageChange} />
      <div className="header__nav">
        <div>
          <Link to="/">{renderLogo(props.language)}</Link>
        </div>
        <div className="header__text">
          <FormattedMessage id="header_desc" />
        </div>
      </div>
    </header>
  );
};

export default header;
