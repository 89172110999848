import React from "react";
import PropTypes from "prop-types";

import "../styles/common.css";
import InfoBlock from "../components/infoblock";
import { ReactComponent as Icon1 } from "../images/house.svg";
import { ReactComponent as Icon2 } from "../images/law.svg";
import { ReactComponent as Icon3 } from "../images/building.svg";

class Home extends React.Component {
  render() {
    return (
      <div className="info-blocks">
        <InfoBlock
          title="info_block-consumer_title"
          desc="info_block-consumer_desc"
        >
          <Icon1 />
        </InfoBlock>
        <InfoBlock
          title="info_block-authoritative_title"
          desc="info_block-authoritative_desc"
        >
          <Icon2 />
        </InfoBlock>
        <InfoBlock
          title="info_block-trader_title"
          desc="info_block-trader_desc"
        >
          <Icon3 />
        </InfoBlock>
      </div>
    );
  }
}

Home.propTypes = {
  organisaatioId: PropTypes.number,
};

export default Home;
